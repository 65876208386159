import * as React from "react";

const SvgFanHp = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		width={209.677}
		height={153.648}
		viewBox="0 0 209.677 153.648"
		{...props}
	>
		<defs>
			<clipPath id="clip-path">
				<path
					id="Path_24"
					data-name="Path 24"
					d="M93.2,603h95.98v63.394H93.2Zm0,0"
					transform="translate(-93.195 -603)"
				/>
			</clipPath>
			<clipPath id="clip-path-2">
				<path
					id="Path_26"
					data-name="Path 26"
					d="M515,232.926h38.2V373.771H515Zm0,0"
					transform="translate(-515 -232.926)"
				/>
			</clipPath>
		</defs>
		<g
			id="Group_32"
			data-name="Group 32"
			transform="matrix(0.996, -0.087, 0.087, 0.996, -2482.579, -5157.958)"
		>
			<g
				id="Untitled_design_1_"
				data-name="Untitled design (1)"
				transform="translate(2016 5365)"
			>
				<path
					id="Path_20"
					data-name="Path 20"
					d="M595.992,478.842s-11.765-34.671,0-57.2a18,18,0,0,0,4.045,9.26s-2.575-9.878,5.353-27.471c-.413,5.864,2.367,8.333,2.367,8.333s-.619-14.815,11.425-29.323a94.926,94.926,0,0,1,0,9.57s3.4-14.2,14.514-24.078a6.2,6.2,0,0,0,0,7.409c6.175-12.039,20.072-10.5,20.072-10.5s8.337-.308,9.264-5.865c.308,17.9-19.765,29.016-19.765,29.016a15.462,15.462,0,0,0,8.958-1.853c.308,8.336-16.676,25.62-16.676,25.62s2.779,1.235,6.485-1.234c-4.015,9.878-21,24.386-21,24.386s.3,1.234,5.863-1.543c-2.775,15.742-16.054,24.384-16.054,24.384s3.4,4.629,7.1,0c-.991,11.134-21.956,21.089-21.956,21.089"
					transform="translate(-488.017 -332.948)"
					fill="#607eb2"
				/>
				<path
					id="Path_21"
					data-name="Path 21"
					d="M305.355,478.842s11.765-34.671,0-57.2a18.006,18.006,0,0,1-4.045,9.26s2.575-9.878-5.353-27.471c.412,5.864-2.367,8.333-2.367,8.333s.618-14.815-11.426-29.323a95.433,95.433,0,0,0,0,9.57s-3.4-14.2-14.514-24.078a6.2,6.2,0,0,1,0,7.409c-6.176-12.039-20.073-10.5-20.073-10.5s-8.337-.308-9.263-5.865c-.308,17.9,19.764,29.016,19.764,29.016a15.454,15.454,0,0,1-8.955-1.853c-.31,8.336,16.675,25.62,16.675,25.62s-2.78,1.235-6.485-1.234c4.014,9.878,21,24.386,21,24.386s-.3,1.234-5.864-1.543c2.777,15.742,16.056,24.384,16.056,24.384s-3.4,4.629-7.1,0c.991,11.134,21.956,21.089,21.956,21.089"
					transform="translate(-208.345 -332.948)"
					fill="#472540"
				/>
				<path
					id="Path_22"
					data-name="Path 22"
					d="M621.52,666.781s5.718-29.242,23.143-40.4a14.642,14.642,0,0,0-.889,8.176s2.177-8.017,14.892-17.239c-2.662,3.976-1.7,6.842-1.7,6.842s5.555-10.713,19.932-16.092a77.368,77.368,0,0,1-3.873,6.758s8.144-8.655,19.991-11.137a5.048,5.048,0,0,0-3,5.231c9.232-6.005,18.421.7,18.421.7s6.015,3.153,8.917-.4c-7.025,12.77-25.695,12.5-25.695,12.5a12.57,12.57,0,0,0,7.073,2.312c-3.153,6.012-22.14,11.354-22.14,11.354s1.462,2,5.08,1.75c-6.832,5.352-24.7,8.734-24.7,8.734s-.286.995,4.766,1.281c-8.329,10-21.2,10.733-21.2,10.733s.525,4.641,5.016,2.87c-5.2,7.464-24.04,6.02-24.04,6.02"
					transform="translate(-512.423 -527.127)"
					fill="#f5b926"
				/>
				<g
					id="Group_23"
					data-name="Group 23"
					transform="translate(0 76.419)"
					clipPath="url(#clip-path)"
				>
					<path
						id="Path_23"
						data-name="Path 23"
						d="M189.27,666.781s-5.716-29.242-23.139-40.4a14.664,14.664,0,0,1,.888,8.176s-2.178-8.017-14.894-17.239c2.663,3.976,1.7,6.842,1.7,6.842s-5.557-10.713-19.932-16.092a77.082,77.082,0,0,0,3.871,6.758s-8.143-8.655-19.991-11.137a5.047,5.047,0,0,1,3,5.231c-9.233-6.005-18.422.7-18.422.7s-6.014,3.153-8.916-.4c7.024,12.77,25.7,12.5,25.7,12.5a12.576,12.576,0,0,1-7.073,2.312c3.153,6.012,22.139,11.354,22.139,11.354s-1.462,2-5.078,1.75c6.83,5.352,24.694,8.734,24.694,8.734s.287.995-4.766,1.281c8.328,10,21.2,10.733,21.2,10.733s-.528,4.641-5.018,2.87c5.2,7.464,24.037,6.02,24.037,6.02"
						transform="translate(-93.384 -603.546)"
						fill="#f5b926"
					/>
				</g>
				<g
					id="Group_24"
					data-name="Group 24"
					transform="translate(87.101 0)"
					clipPath="url(#clip-path-2)"
				>
					<path
						id="Path_25"
						data-name="Path 25"
						d="M528.387,373.778s26-27.248,24.793-53.361a18.538,18.538,0,0,1-7.786,6.887s6.683-8.1,6.914-27.946c-2.158,5.649-5.821,6.749-5.821,6.749s7-13.553,2.052-32.3a97.634,97.634,0,0,0-4.148,8.927s2.986-14.717-3.1-28.746a6.379,6.379,0,0,1-3.211,6.91c-.544-13.9-14.174-18.481-14.174-18.481s-7.644-3.9-6.1-9.482c-8.049,16.566,5.859,35.624,5.859,35.624a15.9,15.9,0,0,1-7.551-5.606c-3.9,7.641,4.451,31.12,4.451,31.12s-3.128-.052-5.513-3.961c-.537,10.952,9.018,31.841,9.018,31.841s-.817,1.021-4.8-3.978c-4.234,15.886,4.407,29.7,4.407,29.7s-5.176,2.849-6.625-3.075c-3.9,10.815,11.339,29.181,11.339,29.181"
						transform="translate(-515.022 -232.932)"
						fill="#e43230"
					/>
				</g>
			</g>
		</g>
	</svg>
);

export default SvgFanHp;
