import React from "react";
import Col from "react-bootstrap/Col";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const ArticleCard = ({ title, image, uri, date, imageAlt, author }) => {
	return (
		<Col className="text-center" md={6} lg={4} xl={3}>
			<GatsbyImage image={image} alt={imageAlt} />

			<p className="shortstack fs-2 text-secondary pt-3">{title}</p>
			<p className="jost-normal text-secondary">{date}</p>
			<p className="carnival-normal text-primary">{author}</p>

			<a
				className="fs-5 text-decoration-none link-purple jost-bold"
				as={Link}
				onClick={() => (window.location = `/news/${uri}`)}
				href="#"
			>
				READ MORE
			</a>
		</Col>
	);
};

export default ArticleCard;
