import { Link, useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import ArticleCard from "../components/articleCard";
import parse from "html-react-parser";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import BottomBanner from "../components/bottom-banner";
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import SvgFanHp from "../components/svg/fan-svg-hp";
import { GatsbyImage } from "gatsby-plugin-image";
import HubspotForm from "react-hubspot-form";

const EntrantFormPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Home-Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "News",
				item: {
					url: `${siteUrl}/news`,
					id: `${siteUrl}/news`,
				},
			},
		],
	};

	return (
		<Layout>
			<div style={{ overflow: "hidden" }}>
				<Helmet>
					{" "}
					<script type="application/ld+json">
						{JSON.stringify(breadcrumb)}
					</script>
				</Helmet>

				<GatsbySeo
					title="News | Eastbourne Carnival"
					description="News about Eastbourne Carnival"
					language="en"
					openGraph={{
						type: "website",
						url: `${siteUrl}/news`,
						title: "News | Eastbourne Carnival",
						description: "News about Eastbourne Carnival",
						images: [
							{
								url: `${data.heroImg?.localFile.publicURL}`,
								width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
								height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
								alt: `${data.heroImg?.altText}`,
							},
						],
					}}
				/>

				<section className="pt-5 pb-md-10  position-relative">
					<Container>
						<Row className="justify-content-center pb-lg-7 ">
							<Col lg={12}>
								<h1 className=" text-center display-4 pb-4 carnival-normal text-secondary">
									Entrants - Conditions of entry
								</h1>
							</Col>
						</Row>
						<Row>
							<Col>
								<p>
									1. Vehicle maximum height from the ground – 13ft, maximum
									width – 8ft. No more than 7.5 tonnes, unless agreed directly
									with the organisers.
								</p>
								<p>
									2. It is the responsibility of all owners of participating
									motor vehicles to notify their insurers with their intended
									use and ensure that all vehicles are street legal.
								</p>
								<p>
									3. Vehicle entrants and their group are required to provide
									their own liability insurances in respect of any injury
									arising or damaged caused to third parties, person or property
									in relation to the event. Vehicles that cause damage to
									pavements, roads, buildings etc. will be charged for the cost
									of repair either directly or through their insurance. This may
									not cover participants so please check with your own insurers
									or obtain a waiver–a copy of this must be attached to the
									entry form.
								</p>
								<p>
									4. Floats are requested not to carry people on the back to or
									from the venue unless in the carnival procession (with
									appropriate insurance)
								</p>
								<p>
									5. Each vehicle entry must provide two Walking Marshals
									alongside the front wheels at all times, wearing Hi-Viz Safety
									wear.
								</p>
								<p>
									6. A separate entry form must be used for each entry if
									entering more than one float
								</p>
								<p>7. Fire extinguishers must be carried on all vehicles.</p>
								<p>8. First Aid Boxes must be carried on all vehicles.</p>
								<p>
									9. Entrants will be given a position in the parade. It is a
									condition of entry that entrants remain in their position in
									line up and throughout the procession.
								</p>
								<p>
									10. Entrants must present at the line up area by 1pm ready for
									the Mayor of Eastbourne to judge their entry.
								</p>
								<p>
									11. Balloons are NOT permitted at Eastbourne Carnival. This is
									because they cause significant harm to Marine life.
								</p>
								<p>
									12. Single use plastic is prohibited (where relevant) i.e.
									plastic bags, drinks bottles, plastic cutlery, single use food
									containers and plastic cups.
								</p>
								<p>13. Entrants are discouraged from giving out stickers.</p>
								<p>
									14. Entrants are required to take responsibility of their own
									waste generated by their entry and take back and recycle.
								</p>
								<p>15. Under 16’s must be accompanied by a guardian.</p>
								<p>
									16. No parking at Assembly Area unless otherwise previously
									agreed.
								</p>
								<p>
									17. All participants to respect the property of local
									residents and businesses.
								</p>
								<p>
									18. All monies collected are for Eastbourne Carnival only and
									are to be handed in at the Collection Point (TBC) No private
									collections allowed. The money raised will be shared with
									qualifying charities / non profits on a 50% basis to be
									divided up across qualifying entrants.
								</p>
								<p>
									19. The throwing of any articles or squirting of liquids from
									floats or by pedestrians is banned.
								</p>
								<p>
									20. The use of flares or other explosives such as fireworks is
									prohibited.
								</p>
								<p>
									21.The Eastbourne Carnival Committee reserves the right to
									refuse any entry.
								</p>
								<p>
									For more information, please see our FAQ Page or contact us
									with specific queries.
								</p>
								<Link
									className="fs-5 text-decoration-none link-purple jost-bold"
									as={Link}
									to="/further-information"
								>
									MORE INFORMATION
								</Link>
							</Col>
						</Row>
						<Row>
							<Col className="pt-5">
								{" "}
								<HubspotForm
									region="eu1"
									portalId="25477959"
									formId="4d922abb-db64-45e2-ac8f-98e631604a18"
									onSubmit={() => console.log("Submit!")}
									onReady={(form) => console.log("Form ready!")}
									loading={<div>Loading...</div>}
								/>
							</Col>
						</Row>
					</Container>
				</section>
				<BottomBanner
					mainBg="bg-secondary"
					position="top--20"
					content={
						<Row>
							<Col className="py-5 pb-md-0 mt-md-5 pt-md-10">
								<h2 className="text-white text-center  fs-1 shortstack">
									FOLLOW US ON SOCIAL MEDIA
								</h2>
								<div className="d-flex pt-4 justify-content-center align-items-center">
									<a
										rel="noreferrer"
										target="_blank"
										href="https://www.facebook.com/eastbournecarnival"
									>
										<FaFacebookF className="text-white display-2 link-white" />
									</a>
									<a
										rel="noreferrer"
										target="_blank"
										href="https://twitter.com/EBCarnival"
									>
										<FaTwitter className="text-white display-2 ms-5 link-white" />
									</a>
									<a
										rel="noreferrer"
										target="_blank"
										href="https://www.instagram.com/eastbournecarnival/"
									>
										<FaInstagram className="text-white display-2 ms-5 link-white" />
									</a>
									<a
										rel="noreferrer"
										target="_blank"
										href="https://www.youtube.com/@eastbournecarnival990"
									>
										<FaYoutube className="text-white display-2 ms-5 link-white" />
									</a>
								</div>
							</Col>
						</Row>
					}
				/>
			</div>
		</Layout>
	);
};

export default EntrantFormPage;
